'use client'

import { useLocale } from 'next-intl'

import { Typo, type TypoProps } from '~/components/Typo'

const postDate = 1_722_947_925_667

export const HomeAnnouncementDate: React.FC<TypoProps> = ({
  className,
  ...props
}) => {
  const locale = useLocale()

  const formattedDate = new Intl.DateTimeFormat(locale, {
    dateStyle: 'medium',
  }).format(postDate)

  return (
    <Typo className={className} {...props}>
      {formattedDate}
    </Typo>
  )
}
